import React, { useState, useRef, useEffect } from 'react';
import { Flex } from 'reflexbox';
import Media from 'react-media';
import { useHistory } from 'react-router-dom';
import BotsIcon from '../../icons/BotsIcon';
import BooIcon from '../../icons/BooIcon';
import ProfileIcon from '../../icons/ProfileIcon';
import NotificationIcon from '../../icons/NotificationIcon';
import NavContainer from './NavContainer';
import NavGroup from './NavGroup';
import NavTitle from './NavTitle';
import ProfileSection from './ProfileSection';
import FloatingMenu from '../FloatingMenu';
import NotificationsList from './NotificationList';
import HovereableFlex from '../common/HovereableFlex';
import Breadcrumb from '../Breadcrumb';

const BOSS_TYPE = {
  SOC: 'SOC',
  NOC: 'NOC',
};
const NAV_MOBILE_MAXWIDTH = '600px';

const HomeLink = ({ iconType, blockNavigation = false }) => {
  const history = useHistory();
  const homeNavigation = () => {
    if (!blockNavigation) {
      history.push('/');
    }
  };
  return (
    <Flex style={{ cursor: 'pointer' }} onClick={homeNavigation}>
      {iconType === BOSS_TYPE.NOC ? <BooIcon size={40} /> : <BotsIcon size={40} />}
    </Flex>
  );
};

function Nav({
  title = '',
  breadcrumbs = [],
  appType,
  onLogOut = () => {},
  userDisplayName = '',
  userEmail = '',
  loggedIn = false,
  hideUserSettings = false,
  hideUserNotifications = false,
  userNotifications = [],
  onOpenNotifications = () => {},
  onCloseNotifications = () => {},
  onRemoveNotification = () => {},
  onRemoveAllNotifications = () => {},
}) {
  const [profileVisible, setProfileVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [areNewNotifications, setNewNotificationsMark] = useState(false);
  const history = useHistory();
  const profileRef = useRef(null);
  const notififcationRef = useRef(null);

  useEffect(() => {
    if (!notificationsVisible) {
      setNewNotificationsMark(userNotifications.some((n) => n.read === null));
    }
  }, [userNotifications]);

  const hideSections = () => {
    setNotificationsVisible(false);
    setProfileVisible(false);
  };

  useEffect(() => {
    if (profileVisible || notificationsVisible) {
      window.addEventListener('scroll', hideSections);
    } else {
      window.removeEventListener('scroll', hideSections);
    }
    return () => window.removeEventListener('scroll', hideSections);
  }, [profileVisible, notificationsVisible]);

  const onNotificationIconClick = () => {
    setNewNotificationsMark(false);
    setNotificationsVisible(true);
    onOpenNotifications();
  };

  const onCloseNotificationsClick = () => {
    setNotificationsVisible(false);
    onCloseNotifications();
  };

  return (
    <Media
      queries={{
        s: `(max-width: ${NAV_MOBILE_MAXWIDTH})`,
        m: '(max-width: 900px)',
      }}
    >
      {(screenSize) => (
        <>
          <NavContainer>
            <NavGroup>
              <HomeLink iconType={appType} />
              {!screenSize.s && breadcrumbs.length > 0 && (
                <Flex ml="20px">
                  <Breadcrumb crumbs={breadcrumbs} />
                </Flex>
              )}
            </NavGroup>
            {!screenSize.s && <NavTitle>{title}</NavTitle>}
            <NavGroup right>
              {loggedIn && (
                <>
                  <HovereableFlex
                    mr="20px"
                    onClick={!hideUserNotifications && onNotificationIconClick}
                    ref={notififcationRef}
                    disable={hideUserNotifications}
                  >
                    <NotificationIcon showDot={areNewNotifications} />
                  </HovereableFlex>
                  <Flex onClick={() => setProfileVisible(true)} ref={profileRef}>
                    <ProfileIcon active={profileVisible} />
                  </Flex>
                </>
              )}
            </NavGroup>
          </NavContainer>
          {screenSize.s && title && (
            <Flex
              style={{
                color: '#898989',
                fontSize: '16px',
                width: '100%',
                height: '30px',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                backgroundColor: '#1d1d1d',
                paddingLeft: '20px',
                paddingRight: '5px',
                wordBreak: 'break-all',
                textAlign: 'left',
              }}
            >
              {title.substr(0, 70)}
            </Flex>
          )}
          {profileVisible && (
            <FloatingMenu
              onBackgroundClick={() => setProfileVisible(false)}
              mobile={screenSize.m}
              parentRef={profileRef}
            >
              <ProfileSection
                displayName={userDisplayName}
                email={userEmail}
                hideSettings={hideUserSettings}
                onLogOut={() => {
                  setProfileVisible(false);
                  onLogOut();
                }}
                onAccountGo={() => {
                  setProfileVisible(false);
                  history.push('/account');
                }}
              />
            </FloatingMenu>
          )}
          {notificationsVisible && (
            <FloatingMenu
              onBackgroundClick={onCloseNotificationsClick}
              mobile={screenSize.m}
              parentRef={notififcationRef}
            >
              <NotificationsList
                onHideList={() => setNotificationsVisible(false)}
                notifications={userNotifications}
                onRemoveNotification={onRemoveNotification}
                onRemoveAllNotifications={onRemoveAllNotifications}
              />
            </FloatingMenu>
          )}
        </>
      )}
    </Media>
  );
}

export default Nav;
