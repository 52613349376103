import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Auth, API } from 'aws-amplify';
import { Flex } from 'reflexbox';
import { LoaderIcon, Nav } from 'boss-ui';
import get from 'lodash/get';
import { ToastContainerNotification, NotificationsHandler } from './components';
import { AppContext } from './libs/context-lib';
import { formatLoggedInUser } from './libs/cognito-lib';
import Theme from './theme';
import Routes from './Routes';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { setUserHeaders, getAppType } from './libs/utils-lib';
import { onError } from './libs/error-lib';
import { useQuery } from './libs/hooks-lib';
import { ALERTABLE_NOTIFICATIONS } from './libs/notifications-lib';

function App() {
  const [user, userSession] = useState(null);
  const [event, setEvent] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [navTitle, setNavTitle] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const appType = useMemo(() => getAppType(), []);
  const query = useQuery();
  const internalRedirect = query.get('redirect');

  const setDynamoUser = async (cognitoUser) => {
    try {
      const registeredUser = await API.get('users', '/user');
      if (!registeredUser.user) {
        userSession(cognitoUser);
        history.push('/firstLogin');
        return;
      }
      userSession({ ...cognitoUser, dynamoUser: registeredUser.user });
      setUserHeaders({ ...cognitoUser, dynamoUser: registeredUser.user });
      window.Rollbar.configure({
        payload: {
          person: {
            id: registeredUser.user.userId,
            email: registeredUser.user.email,
            username: registeredUser.user.displayName,
          },
        },
      });
      window.Rollbar.info('Client session started');
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    async function setLoggedInUser() {
      try {
        const loggedUser = formatLoggedInUser(await Auth.currentAuthenticatedUser());
        await setDynamoUser(loggedUser);
      } catch (e) {
        history.push(
          internalRedirect
            ? `/login?redirect=${internalRedirect}`
            : `/login?redirect=${pathname}${search}`
        );
      } finally {
        setIsAuthenticating(false);
      }
    }

    // avoid setting the user in app state if path is the saml idp redirection
    if (pathname !== '/successLogin') {
      setLoggedInUser();
    } else {
      setIsAuthenticating(false);
    }
  }, []);

  function handleLogout() {
    history.push('/logout');
  }

  if (isAuthenticating) {
    return (
      <Flex mt="15%" alignItems="center" justifyContent="center" flexDirection="column">
        <LoaderIcon appType={appType} />
      </Flex>
    );
  }

  const userDisplayName = get(user, 'dynamoUser.displayName');
  const userEmail = get(user, 'dynamoUser.email');
  const hideUserProfileActions = !user || !user.dynamoUser;

  // display list and remove new mark
  const onOpenNotifications = async () => {
    try {
      const notificationsRq = await API.get('users', '/user/notifications');
      const alertableNotifications = [];
      notificationsRq.notifications.forEach((n) => {
        if (ALERTABLE_NOTIFICATIONS.includes(n.notificationType)) {
          alertableNotifications.push(n);
        }
      });
      setNotifications(alertableNotifications);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  // hide list and mark all unread notifications to read
  const onCloseNotifications = async () => {
    const notificationsPromises = notifications.map(async (n) => {
      if (n.read) {
        return n;
      }
      try {
        await API.patch('users', `/user/notifications/${n.createdAt}`, {
          body: { read: +new Date() },
        });
        return { ...n, read: true };
      } catch (e) {
        return n;
      }
    });
    const readedNotifications = await Promise.all(notificationsPromises);
    setNotifications(readedNotifications);
  };

  const onRemoveNotification = async (notification) => {
    try {
      const { createdAt } = notification;
      await API.del('users', `/user/notifications/${createdAt}`);
      setNotifications((notificationsBase) =>
        notificationsBase.filter((n) => n.createdAt !== createdAt)
      );
    } catch (e) {
      onError('Failed to remove notification');
    }
  };

  const onRemoveAllNotifications = async () => {
    const removeNotificationsPromises = [];
    notifications.forEach((n) => {
      try {
        removeNotificationsPromises.push(API.del('users', `/user/notifications/${n.createdAt}`));
      } catch (e) {
        onError('Failed to remove notification');
      }
    });
    await Promise.all(removeNotificationsPromises);
    setNotifications([]);
  };

  return (
    <div className="app-container">
      <ThemeProvider theme={Theme}>
        <ToastContainerNotification
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AppContext.Provider
          value={{
            user,
            userSession,
            event,
            setEvent,
            setNavTitle,
            appType,
            notifications,
            setNotifications,
          }}
        >
          <div className="app-header">
            <Nav
              title={navTitle}
              appType={appType}
              onLogOut={handleLogout}
              userDisplayName={userDisplayName}
              userEmail={userEmail}
              userNotifications={notifications}
              onOpenNotifications={onOpenNotifications}
              onCloseNotifications={onCloseNotifications}
              loggedIn={user}
              hideUserSettings={hideUserProfileActions}
              hideUserNotifications={hideUserProfileActions}
              onRemoveNotification={onRemoveNotification}
              onRemoveAllNotifications={onRemoveAllNotifications}
            />
          </div>
          <NotificationsHandler />
          <Routes />
        </AppContext.Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
