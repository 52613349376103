import Rb from 'rollbar';

export default function rollbarConfig(config) {
  if (config) {
    try {
      const rollbar = new Rb(config);
      return rollbar;
    } catch (e) {
      console.error('Error initializating rollbar', e);
    }
  }
  return null;
}
