import { useEffect } from 'react';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import { useRouteMatch } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useAppContext } from '../../libs/context-lib';
import WebSocketClient, { WEB_SOCKET_STATUS } from '../websocket/index';
import {
  ALERTABLE_NOTIFICATIONS,
  formatNotificationMessage,
  ACTIONABLE_NOTIFICATIONS,
} from '../../libs/notifications-lib';
import config from '../../config';
import { onError } from '../../libs/error-lib';

const wsClient = new WebSocketClient();

export default function NotificationsHandler() {
  const { user, userSession, setNotifications } = useAppContext();
  const eventRouteMatch = useRouteMatch('/event/:eventId');

  async function performNotificationAction(notification) {
    const notificationEventId = get(notification, 'notification.eventId');
    const pathEventId = get(eventRouteMatch, 'params.eventId');
    if (!notificationEventId || !pathEventId || notificationEventId !== pathEventId) {
      return;
    }
    switch (notification.notificationType) {
      case 'TEAM_INVITE_DISBAND':
        userSession((u) => ({ ...u, userInTeam: null }));
        break;
      case 'TEAM_INVITE_REMOVED':
        userSession((u) => ({ ...u, userInTeam: null }));
        break;
      default:
    }
  }

  const wsMessageHandler = (e = {}) => {
    const { data } = e;
    if (!data) {
      return;
    }
    if (ALERTABLE_NOTIFICATIONS.includes(data.notificationType)) {
      toast(formatNotificationMessage(data));
      setNotifications((notif) => [data].concat(notif));
    }
    if (ACTIONABLE_NOTIFICATIONS.includes(data.notificationType)) {
      performNotificationAction(data);
    }
  };

  useEffect(() => {
    async function getNotifications() {
      try {
        const notificationsRq = await API.get('users', '/user/notifications');
        // this need to handle pagination
        const alertableNotifications = [];
        notificationsRq.notifications.forEach((n) => {
          if (ALERTABLE_NOTIFICATIONS.includes(n.notificationType)) {
            alertableNotifications.push(n);
          }
        });
        setNotifications(alertableNotifications);
      } catch (e) {
        onError(e);
      }
    }
    // prevent the notifications operations if there is no logged in user and the socket is OPEN / RECONNECTING
    if (
      user &&
      user.dynamoUser &&
      wsClient.state !== WEB_SOCKET_STATUS.OPEN &&
      wsClient.state !== WEB_SOCKET_STATUS.RECONNECTING
    ) {
      getNotifications();
      try {
        wsClient.open({ url: config.webSocket.URL, authenticateRq: true });
      } catch (e) {
        window.Rollbar.error('WS ERROR', JSON.stringify(e));
      }
    }
    if (wsClient.state === WEB_SOCKET_STATUS.OPEN) {
      wsClient.on('message', wsMessageHandler);
    }
    return () => {
      wsClient.removeListener('message', wsMessageHandler);
    };
  }, [user, wsClient.state]);

  return null;
}
