import React, { useEffect, useState } from 'react';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import { useLocation, useHistory } from 'react-router-dom';
import { Flex } from 'reflexbox';
import Api from '../../api';
import { LoaderIcon, RegularText } from 'boss-ui';
import { onError } from '../../libs/error-lib';
import { getHashObjectInURL } from '../../libs/utils-lib';
import config from '../../config';

const API = new Api();

export default function DiscordJoinRedirection() {
  const [interval, setCallInterval] = useState(null);
  const [loadingText, setLoadingText] = useState('Getting discord user');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const getDiscordUser = async () => {
      try {
        const { access_token, state } = getHashObjectInURL(location);
        const rq = await fetch(
          `${config.discord.DISCORD_API_URL}/${config.discord.GET_DISCORD_USER_PATH}`,
          {
            method: 'GET',
            withCredentials: true,
            credentials: 'include',
            headers: {
              Authorization: `Bearer ${access_token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        const discordUser = await rq.json();
        setLoadingText('Setting channel permissions');
        const { redirectPath, teamId, inviteUrl } = JSON.parse(decodeURIComponent(state));
        await API.post('teams', `/teams/${teamId}/join-discord`, {
          body: { discordUserId: discordUser.id, discordAccessToken: access_token },
        });
        window.open(inviteUrl, '_blank');
        history.push(`${redirectPath}`);
      } catch (e) {
        window.Rollbar.error('Error at discordRedirection: ', JSON.stringify(e), e);
        onError('Something went wrong');
        history.push('/');
      }
    };

    setCallInterval(
      setTimeout(() => {
        clearInterval(interval);
        getDiscordUser();
      }, 2000)
    );

    return () => {
      if (!isUndefined(interval) && !isNull(interval)) {
        clearInterval(interval);
      }
    };
  }, []);

  return (
    <Flex mt="15%" alignItems="center" justifyContent="center" flexDirection="column">
      <LoaderIcon />
      <Flex mt="20px" alignItems="center" justifyContent="center">
        <RegularText>{loadingText}</RegularText>
      </Flex>
    </Flex>
  );
}
