import React, { useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Media from 'react-media';
import { Box, Flex } from 'reflexbox';
import get from 'lodash/get';
import { RegularText, StyledText, BotsIcon, BooIcon, QUESTION_CARD_DEFAULT_WIDTH, Markdown } from 'boss-ui';
import Api from '../../api';
import { onError } from '../../libs/error-lib';
import Card from '../cards/Card';
import CardSkeleton from '../cards/CardSkeleton';
import CollapsableSection from '../CollapsableSection';
import ErrorBoundary from '../ErrorBoundary';
import FloatingMessage from '../FloatingMessage';
import { BOSS_TYPE, DEFAULT_BOX_BORDER_STYLE, EVENT_WORDING } from '../../libs/constants';
import { getEventShortName } from '../../libs/event-lib';

const addScenarioIndex = (scenarios = []) => {
  if (!scenarios.length) {
    return [];
  }
  return scenarios.map((s, idx) => ({
    ...s,
    idx: idx + 1,
  }));
};

const getGridConfiguration = (matches) => {
  if (matches.s) {
    return '1fr';
  }
  if (matches.m) {
    return '1fr 1fr';
  }
  if (matches.l) {
    return '1fr 1fr 1fr';
  }
  if (matches.xl) {
    return '1fr 1fr 1fr 1fr';
  }
  return `${QUESTION_CARD_DEFAULT_WIDTH} ${QUESTION_CARD_DEFAULT_WIDTH} ${QUESTION_CARD_DEFAULT_WIDTH}`;
};

const API = new Api();

export default function EventHome({
  eventScenarios,
  eventType,
  eventId,
  isOnDemand,
  isUserWithOutTeam,
  eventDescription,
  eventRules,
  appType,
}) {
  const [scenarios, setScenarios] = useState([]);
  const [loadingScenarios, setLoadingScenarios] = useState(true);
  const history = useHistory();
  const scenarioLabel = useMemo(() => EVENT_WORDING.SCENARIO[eventType], [eventType]);

  useEffect(() => {
    // keep this in order to replace the call when scenarios have his own endpoint
    const getEventScenarios = async () => {
      setLoadingScenarios(true);
      try {
        const rq = await API.get('events', `/events/${eventId}`);
        const scenariosWithIdx = addScenarioIndex(get(rq, 'event.scenarios'));
        setScenarios(scenariosWithIdx);
      } catch (e) {
        onError(e);
      } finally {
        setLoadingScenarios(false);
      }
    };

    if (!eventScenarios.length) {
      getEventScenarios();
    } else {
      const scenariosWithIdx = addScenarioIndex(eventScenarios);
      setScenarios(scenariosWithIdx);
      setLoadingScenarios(false);
    }
  }, [eventScenarios]);

  const eventIcon = useMemo(
    () => (eventType === BOSS_TYPE.SOC ? <BotsIcon size={200} /> : <BooIcon size={130} />),
    [eventType]
  );

  return (
    <Media
      queries={{
        s: '(max-width: 1000px)',
        m: '(max-width: 1290px)',
        l: '(max-width: 1540px)',
        xl: '(min-width: 1880px)',
      }}
    >
      {(matches) => (
        <>
          {isUserWithOutTeam && !isOnDemand && !matches.s && (
            <FloatingMessage top="120px" left="40px" active>
              <p>Start by creating your team!</p>
              <p>Or join an existing one.</p>
            </FloatingMessage>
          )}
          <Box mx={matches.s ? '20px' : '10%'} mt={matches.s ? '20px' : '40px'} mb="5%">
            <Flex mx={matches.s ? 0 : '20%'} my="22px" style={{ position: 'relative' }}>
              {!matches.s && (
                <Box
                  style={{
                    position: 'absolute',
                    left: '-66px',
                    top: '80px',
                  }}
                >
                  {eventIcon}
                </Box>
              )}
              <Box>
                <StyledText light size="28px">
                  Welcome to {getEventShortName(eventType)}
                </StyledText>
                <Box
                  style={DEFAULT_BOX_BORDER_STYLE}
                  mt="16px"
                  p={matches.s ? '10px' : '40px'}
                  pl={matches.s ? '15px' : '90px'}
                  pr={matches.s ? '15px' : '60px'}
                  textAlign="left"
                >
                  <RegularText light mediumWeight fontSize="16px">
                    <Markdown md={eventDescription} light mediumWeight fontSize="16px" />
                  </RegularText>
                  <Flex mt="30px" flexDirection="column">
                    {eventRules && (
                      <Flex ml="auto">
                        <CollapsableSection
                          justify="flex-end"
                          expanded={false}
                          showLabel="Show Rules"
                          hideLabel="Hide Rules"
                        >
                          <RegularText fontSize="16px" color="#A5D6A7">
                            RULES
                          </RegularText>
                          <Markdown md={eventRules} />
                          {!matches.s && (
                            <Box
                              style={{
                                position: 'absolute',
                                right: '-66px',
                                bottom: '40px',
                              }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/alice_workshops_mobile@2x.png`}
                                alt="alice"
                                height="200px"
                              />
                            </Box>
                          )}
                        </CollapsableSection>
                      </Flex>
                    )}
                  </Flex>
                </Box>
              </Box>
            </Flex>
            <Flex mt="60px" mb="22px" ml={matches.s ? 0 : '52px'}>
              <StyledText light size="28px">
                {scenarioLabel} {!loadingScenarios && `[${scenarios.length}]`}
              </StyledText>
            </Flex>
            <Flex flexDirection="column" width={1}>
              <Flex justifyContent="center">
                {loadingScenarios ? (
                  <CardSkeleton />
                ) : (
                  <Flex
                    style={{
                      display: 'grid',
                      gridTemplateColumns: getGridConfiguration(matches),
                      gridGap: '22px',
                      gridAutoFlow: 'dense',
                    }}
                    className="EventPlayer-FlexScenarios"
                  >
                    <ErrorBoundary ignoreMessage>
                      {scenarios.map((e, idx) => {
                        const key = get(e, 'id', idx);
                        return (
                          <Card
                            {...e}
                            key={key}
                            onCardClick={() =>
                              history.push(`/event/${eventId}/scenario/${e.scenarioId}`)
                            }
                            isScenario
                            scenarioLabel={scenarioLabel}
                            scenarioIdx={idx + 1}
                            type={appType}
                          />
                        );
                      })}
                      ;
                    </ErrorBoundary>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Box>
        </>
      )}
    </Media>
  );
}
