import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Flex } from 'reflexbox';
import BooIcon from '../../icons/BooIcon';
import BotsIcon from '../../icons/BotsIcon';

const BOSS_TYPE = {
  SOC: 'SOC',
  NOC: 'NOC',
};

const animBOTSLogo = keyframes`
0% {
  box-shadow: 0 0 red;
}
100% {
  box-shadow: 0 0 100px 80px transparent;
  border-width: 2px;
}
`;

const animBOOLogo = keyframes`
0% {
  box-shadow: 0 0 #e9a000;
}
100% {
  box-shadow: 0 0 90px 90px transparent;
  border-width: 2px;
}
`;

const IconFrameBOTS = styled.div`
  border-radius: 30%;
  width: ${(props) => (props.size ? `${props.size / 3}px` : '10px')};
  height: ${(props) => (props.size ? `${props.size / 2}px` : '10px')};
  position: absolute;
  animation: ${animBOTSLogo} 1s ease infinite;
  z-index: -1;
`;

const IconFrameBOO = styled.div`
  border-radius: 100%;
  width: ${(props) => (props.size ? `${props.size / 2}px` : '10px')};
  height: ${(props) => (props.size ? `${props.size / 2}px` : '10px')};
  position: absolute;
  animation: ${animBOOLogo} 1s ease infinite;
  z-index: -1;
`;

function LoaderIcon({ size = 300, appType }) {
  return (
    <Flex alignItems="center" justifyContent="center" width={1} style={{ opacity: 0.4 }}>
      {appType === BOSS_TYPE.SOC ? (
        <>
          <BotsIcon size={size} />
          <IconFrameBOTS size={size} />
        </>
      ) : (
        <>
          <BooIcon size={size} />
          <IconFrameBOO size={size} />
        </>
      )}
    </Flex>
  );
}

export default LoaderIcon;
